<template>
  <a-layout-footer class="footer">
    <a
        href="https://github.com/Aclairvoyant"
        target="_blank"
        style="text-decoration: none; color: black"
    >
      @Aclairvoyant
    </a>
  </a-layout-footer>
</template>

<script lang="ts" setup></script>

<style lang="less" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: var(--color-text-2);
  text-align: center;
}
</style>
